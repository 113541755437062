import React from 'react';
import '../../App.css';

export default function Week8() {
  return (
  <> 
  <div className='week8'>
    <h4>did an audio-guided brainstorm activity to learn tips and tricks for generating a lot of ideas and selected ideas based on feasibility and a new criteria: potential to delight the user
    </h4>
    <h2>Brainstorming Metamorphosis: From Restraint to Radical Abundance</h2>

    <p>Prior to IDEA201, brainstorming felt like a tightly leashed dog, circling the same patch of ground. My colleagues and I, a pack of eager go-getters, aimed for feasible, realistic ideas – safe bets in the early stages of development. While we unearthed a few gems, the process felt restrictive, stifling the spark of truly innovative thinking.</p>

    <p>Enter IDEA201 and the liberating world of radical brainstorming. It was like unleashing the dog onto a vast, uncharted plain. Crazy ideas? Bring 'em on! No judgment, just pure exploration. This freedom was exhilarating, allowing me to tap into uncharted territories of my imagination. Ideas that would've never dared peek their heads out in the old regime blossomed like wildflowers.</p>

    <p class="aha">The "Aha" moment: It wasn't just about quantity (though 20+ ideas in minutes was pretty darn impressive!). It was the quality. These weren't mere tweaks on existing concepts; they were leaps of faith, paradigm shifts, solutions I never knew I was searching for. I realized that embracing the "crazy" wasn't about foolishness, it was about breaking free from self-imposed constraints and opening the door to the truly unexpected.</p>

    <h3 class="takeaways">Takeaways:</h3>

    <ul>
      <li>Feasibility can wait: In the initial stages, let imagination run wild. You can refine later.</li>
      <li>Embrace the "what if?": The most outlandish ideas can spark connections and lead to groundbreaking solutions.</li>
      <li>Quantity breeds quality: Don't censor, just generate. You might surprise yourself with the hidden gems buried beneath the seemingly absurd.</li>
    </ul>

    <h3 class="why">Why this shift matters:</h3>

    <ul>
      <li>Innovation thrives in the wild: Radical brainstorming fosters an environment where even the most improbable ideas have a chance to flourish.</li>
      <li>It's all about perspective: Seeing the familiar through a lens of "crazy" can lead to revolutionary insights.</li>
      <li>Unlocking hidden potential: We all have a wellspring of creativity waiting to be tapped. Radical brainstorming gives us the tools to unleash it.</li>
    </ul>
    </div>
  </>);
}
