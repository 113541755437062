import React from 'react';
import '../../App.css';

export default function week4() {
  // return (<>

    return (
    <>
       <div className='week4'><div>
        <h4>The Big Ideas we explored so far...</h4>
        <p>Week 4: How to talk to humans</p>
        <h4>And What you did for each...</h4>
        <p>practiced talking to humans by saying hi either in-person or virtually and asking for help from your network</p>
        
        <h4>Reconnecting with Old Teammates</h4>

          <h2>Big Idea: How to Talk to Humans</h2>

          <p>After a few years of no contact, I decided to reach out to three former teammates I had connected with during hackathons and online CTFs. I sent them casual messages on Discord, expressing my curiosity about their current endeavors. Two of them responded promptly, and we engaged in enjoyable conversations about our recent projects and experiences.</p>

          <h4>"Aha" Moment:</h4>

          <p>Initiating conversations with former teammates was surprisingly easy and natural. Despite the initial hesitation, it felt effortless to connect with them through text messages, a medium I find more comfortable than face-to-face interactions.</p>

          <h4>Takeaways:</h4>

          <ul>
            <li>Overcoming the initial awkwardness of reaching out to old acquaintances can lead to meaningful connections and rewarding conversations.</li>
            <li>Text messages can be an effective tool for initiating and maintaining communication, especially for those who prefer asynchronous interactions.</li>
            <li>Reconnecting with past collaborators can rekindle shared passions and foster a sense of nostalgia.</li>
          </ul>

          <h4>"Why" I Picked This Big Idea:</h4>

          <p>The experience of reconnecting with my old teammates aligns with the Big Idea "How to Talk to Humans" in several ways:</p>

          <ul>
            <li>It highlights the importance of taking initiative and overcoming self-imposed barriers to initiate conversations.</li>
            <li>It demonstrates the effectiveness of communication, even through text-based platforms, in building connections and fostering relationships.</li>
            <li>It underscores the value of shared experiences and passions in strengthening bonds with others.</li>
          </ul>

          <h4>Overall:</h4>

          <p>Reaching out to my old teammates was a rewarding experience that reinforced the significance of human connection and effective communication.</p>
        
          </div>
   </div> 
    </>
  );
}
