import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Check out these EPIC artifacts!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img-3.jpg'
              text='Week 10: How to draw people into your idea'
              label='Week10'
              path='/week10'
            />
            <CardItem
              src='images/img-4.jpg'
              text='Week 4: How to talk to humans'
              label='Week4'
              path='/Week4'
            />
            <CardItem
              src='images/img-8.jpg'
              text='Week 8: How to be an idea-generating machine'
              label='Week8'
              path='/Week8'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}
// Was most unexpected (or turned out mostly differently than what you expected)
// Didn't quite resonate with you (didn't really hit the mark for you or you don't see much application for it)
// Was most transformative (and you definitely see yourself using going forward)
export default Cards;
