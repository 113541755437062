import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Week4 from './components/pages/Week4';
import Week8 from './components/pages/Week8';
import Week10 from './components/pages/Week10';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Router path='/IDEA' exact component={Home} />
          <Route path='/' exact component={Home} />
          <Route path='/Week4' component={Week4} />
          <Route path='/Week8' component={Week8} />
          <Route path='/Week10' component={Week10} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
