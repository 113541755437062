import React from 'react';
import '../../App.css';

export default function week10() {

  return (
    <>
      <div className='week10'><div>
        <h2>From Paralyzed Perfectionism to Progress: An IDEA201 Odyssey</h2>

        <p>Before weeks 6-10 of IDEA201, my creativity was a timid mouse, hiding in the shadows of self-doubt. My ideas? Trapped in a vault, each one deemed unworthy until it reached a mythical state of perfection.</p>

        <h3 class="crippling">The Crippling Grip of Perfectionism:</h3>

        <ul>
          <li>Answerless inaction: I wouldn't even touch an idea without a complete blueprint, stifling the spark before it could ignite. "No answer, no action" was my mantra, a self-imposed prison of my own making.</li>
          <li>Sharing's kryptonite: Exposing my ideas felt like baring my soul to a merciless judge. Only flawless gems deserved the light, and mine, well, they were far from polished diamonds.</li>
          <li>Creativity? Not for me: A heavy cloak of self-belief, woven from past experiences, convinced me that "good ideas" were for a chosen few, not someone like me.</li>
        </ul>

        <h3 class="winds">The IDEA201 Winds of Change:</h3>

        <ul>
          <li>Embrace the unknown: It wasn't about having all the answers; it was about asking the right questions. The thrill of discovery became my compass, guiding me through uncharted territories.</li>
          <li>Feedback, not judgment: Sharing wasn't about validation; it was about growth. Every perspective, every critique, was a brushstroke on my idea's canvas, adding depth and texture.</li>
          <li>Creativity, a muscle to be flexed: It craved exercise, not judgment. With each experiment, each leap outside my comfort zone, the "not good enough" whispers faded, replaced by the confidence to stumble, to fail gloriously, and to rise again.</li>
        </ul>

        <h3 class="aha">Aha! Moments:</h3>

        <ul>
          <li>The BrainSpin revelation: When seemingly disparate ideas collided, sparking a solution I'd never considered. It was the epitome of "thinking outside the box," and it shattered my belief that good ideas only existed within rigid boundaries.</li>
          <li>The prototyping power: Building a rough prototype, testing it with real users, and witnessing their reactions – it was like watching my creation come alive, breathing, evolving. It was a powerful reminder that the messy, imperfect is often where the magic happens.</li>
        </ul>

        <h3 class="takeaways">Takeaways:</h3>

        <ul>
          <li>Progress, not perfection: It's the journey of exploration, not the destination of a flawless idea, that fuels creativity.</li>
          <li>Embrace vulnerability: Sharing, even when it's scary, is the fertilizer that nourishes ideas and helps them blossom.</li>
          <li>Practice makes perfect (or at least, better): Creativity is a muscle, and the more you exercise it, the stronger and more flexible it becomes.</li>
        </ul>

        <h3 class="why">Why These Matter:</h3>

        <p>Because they unlock the door to a world where ideas aren't judged
        </p>
      </div>
      </div>
    </>
  );
}
